import { Api } from './api';
import { IMessage, ISenderID } from '@/types/types';
import { AxiosHeaders } from 'axios';

export type smsType = 'single' | 'multiple';

export const quickSMS = <T>(
  payload: IMessage,
  type: smsType,
  headers?: Record<string, unknown>
): Promise<T> => {
  return Api().post(
    `/sms/send/quick${type === 'multiple' ? '/multiple' : ''}`,
    payload,
    {
      headers: {
        ...headers,
        'x-origin-channelid': 'WEB',
      },
    }
  );
};

export const quickSMSToAllContacts = <T>(
  payload: IMessage,
  headers?: Record<string, unknown>
): Promise<T> => {
  return Api().post(`/sms/send/quick/all/contacts`, payload, {
    headers: {
      ...headers,
      'x-origin-channelid': 'WEB',
    },
  });
};

export const bulkSMS = <T>(
  payload: { messages: IMessage[]; isGlobal: boolean },
  headers?: Record<string, unknown>
): Promise<T> => {
  return Api().post(`/sms/send/bulk`, payload, {
    headers: {
      ...headers,
      'x-origin-channelid': 'WEB',
    },
  });
};

export const creditBalance = <T>(headers?: AxiosHeaders): Promise<T> => {
  return Api().get(`/sms/account/balance`, { headers });
};

export const listSenderID = <T>(query: string): Promise<T> => {
  return Api().get(`/sender-id/list${query}`);
};

export const detailsSenderID = <T>(id: number): Promise<T> => {
  return Api().get(`/sender-id/${id}`);
};
export const newSenderID = <T>(payload: ISenderID): Promise<T> => {
  return Api().post(`/sender-id`, payload);
};

export const deleteSenderID = <T>(id: number): Promise<T> => {
  return Api().delete(`/sender-id/${id}`);
};

export const listPlaceholders = <T>(): Promise<T> => {
  return Api().get(`/message-placeholders`);
};

export const generateTemplate = <T>(body: { message: string }): Promise<T> => {
  return Api().post(`/message-placeholders/generate/template`, body);
};

export const sendPersonalizeSMS = <T>(
  payload: FormData,
  senderId: string,
  headers?: AxiosHeaders
): Promise<T> => {
  return Api().post(
    `/message-placeholders/schedule/personalize/sms?senderId=${senderId}`,
    payload,
    {
      headers: {
        ...headers,
        'x-origin-channelid': 'WEB',
      },
    }
  );
};
