/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthState,
  IChangePassword,
  ILoginResponse,
  IResetPassword,
  IResponse,
  LoginState,
  RootState,
} from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { of } from 'rxjs';
import { AES, enc } from 'crypto-js';

import SecureLS from 'secure-ls';
import router from '@/router';
import { isNetworkError } from '@/utils/helpers';
import {
  login,
  registerAsDeveloper,
  verifyAccount,
  resendVerificationCode,
  updateProfile,
  changePassword,
  forgotPassword,
  resetPassword,
} from '@/services/auth.service';
import { map } from 'rxjs/operators';
import { me } from '@/services/auth.service';
import { IUser, IRegisterState, IVerifyAccounts } from '@/types/types';
import { uploadPhoto } from '@/services/auth.service';

const ls = new SecureLS({
  isCompression: false,
});
const namespaced = true;

const state: AuthState = {
  user: {
    token: '',
    userType: '',
    refreshToken: '',
    details: {
      id: '',
      name: '',
      email: '',
      businessId: {
        id: 0,
        isBusinessReseller: false,
      },
    },
  },
};

const mutations: MutationTree<AuthState> = {
  UPDATE_TOKEN(state, payload: IResponse<ILoginResponse>) {
    state.user.token = payload?.data?.access_token ?? null;
    state.user.userType = 'users';
    state.user.refreshToken = payload?.data?.refresh_token ?? null;
  },
  UPDATE_USER_DETAILS(state, payload) {
    state.user.details = payload;
  },
};
const actions: ActionTree<AuthState, RootState> = {
  async login(
    { commit, dispatch, rootState },
    payload: LoginState & { redirect: string | null }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<ILoginResponse>>(await login(payload));
      response$.subscribe((login) => {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_TOKEN', login);
        if (!login.data.isActive) {
          const query = AES.encrypt(
            payload.username,
            `K4iR0s4Fr1Ka`
          ).toString();
          router
            .replace({
              name: 'accounts.verification',
              query: {
                id: query,
              },
            })
            .catch();
          return;
        }
        // fetch the current user information
        dispatch('me');
        if (payload.redirect || rootState.redirectUrl !== '') {
          router
            .replace({
              path: payload.redirect ?? (rootState?.redirectUrl as string),
            })
            .catch();
        } else {
          router.replace({ name: `account.dashboard` }).catch();
        }
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async registerAsDeveloper({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<
        IResponse<
          IRegisterState & {
            isRegistrationDone: boolean;
            isActive: boolean;
            id: number;
          }
        >
      >(await registerAsDeveloper(payload));
      response$.subscribe((register) => {
        dispatch('isLoading', false, { root: true });
        const { email } = register.data;
        const query = AES.encrypt(email, `K4iR0s4Fr1Ka`).toString();
        dispatch(
          'snackBarMessage',
          `Registration done successfully. Check your email or phone messages to get your verification code`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
        router.replace({
          name: 'accounts.verification',
          query: {
            id: query,
          },
        });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async verifyAccount({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const query = AES.decrypt(payload.id, `K4iR0s4Fr1Ka`).toString(enc.Utf8);
      const response$ = of<IResponse<IVerifyAccounts>>(
        await verifyAccount({ email: query, code: payload.code })
      );
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Account verification done successfully`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        setTimeout(() => {
          router.replace({
            name: 'accounts.signin',
          });
        }, 2000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async resendVerificationCode({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      if (!payload.id) {
        await router.push('/signin');
      }
      const query = AES.decrypt(payload.id, `K4iR0s4Fr1Ka`).toString(enc.Utf8);
      const response$ = of<IResponse<IRegisterState & { id: string }>>(
        await resendVerificationCode({ email: query })
      );
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Verification code successfully sent`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch(
          'snackBarMessage',
          Array.isArray(e.response.data?.message)
            ? e?.response?.data?.message?.join(',')
            : e?.response?.data?.message ?? e?.message,
          {
            root: true,
          }
        );
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async me({ commit, dispatch }) {
    try {
      const response$ = of<IResponse<IUser>>(await me());
      response$.subscribe((profile) => {
        const { password, ...rest } = profile.data;
        commit('UPDATE_USER_DETAILS', {
          ...rest,
          photo: rest.photo
            ? `${process.env.VUE_APP_BASE_URL}/static/${rest.photo}`
            : null,
        });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async updateProfile({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(await updateProfile(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'snackBarMessage',
          `Personal information successfully updated`,
          {
            root: true,
          }
        );
        dispatch('me');
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async uploadPhoto({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<unknown>>(await uploadPhoto(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('me');
      });
    } catch (e) {
      dispatch('isLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async changePassword({ commit, dispatch }, payload: IChangePassword) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<unknown>>(await changePassword(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Password successfully changed`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      dispatch('isLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async forgotPassword({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(
        await forgotPassword(payload)
      ).pipe(map((account) => account.data));
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'snackBarMessage',
          `Check your email address <${account.email}> to follow the instructions in resetting your password.`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
        setTimeout(() => {
          router.replace({
            name: 'accounts.signin',
          });
        }, 2000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async resetPassword({ commit, dispatch }, payload: IResetPassword) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(await resetPassword(payload)).pipe(
        map((account) => account.data)
      );
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Password successfully reset`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        setTimeout(() => {
          router.replace({
            name: 'accounts.signin',
          });
        }, 2000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async logOut({ commit, dispatch }, payload) {
    ls.removeAll();
    commit('UPDATE_TOKEN', payload);
    commit('UPDATE_REDIRECT_URL', '', {
      root: true,
    });
    await router.replace({
      name: 'accounts.signin',
    });
  },
};

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: (state) => {
    return !!state.user.token;
  },
  getToken: (state) => state.user.token,
  getUserDetails: (state) => state.user.details,
  getUserType: (state) => state.user.userType,
};

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
